import { anime, BabyBedtimePromptSet, bw, comic, DreamPromptSet, fairytale, FairytalesPromptSet, handDrawn, manga } from "../chatgpt/chatGptTemplates";
import AiPromptSet, { AiImagePromptSetProps, AiPromptSetProps } from "./AiPromptSet";

export enum LogLevel {
    DEBUG = 0,
    INFO = 1,
    WARN = 2,
    ERROR = 3
};

export enum StoryStatus {
    NOT_STARTED = 0,
    PROMPT_SET_CHOSEN = 10,
    TOPIC_SET = 20,
    TOPIC_ANALYSED = 30,
    TITLE_OPTIONS_SET = 40,
    TITLE_CHOSEN = 50,
    CHARACTERS_IDENTIFIED = 60,
    CHARACTERS_SET = 70,
    TO_FROM_SET = 80,
    DEDICATION_ADDED = 90,
    STORY_WRITTEN = 100,
    STORY_SET = 110,
    IMAGE_PROMPT_SET_CHOSEN = 120,
    CHARACTERS_APPEARANCE_SET = 130,
    VISUAL_DESCRIPTIONS_COVER = 140,
    COVER_IMAGES_GENERATED = 150,
    VISUAL_DESCRIPTIONS_PAGES = 160,
    IMAGES_GENERATED = 170,
    IMAGES_ACCEPTED = 180,
    IMAGES_ENLARGED = 190
}

export enum OrderStatus {
    CREATED = 0,
    FINALISED = 10
}

export enum SocketMessageType {
    REQUEST = "request",
    RESPONSE = "response",
    PUSH = "push",
    ERROR = "error",
    FINISH = "finish"
}

export enum ActionVerb {
    HEARTBEAT = "heartbeat",
    CREATE_STORY = "createStory",
    CREATE_USER = "createUser",
    GET_USER = "getUser",
    UPDATE_USER = "updateUser",
    GET_STORY = "getStory",
    CREATE_ORDER = "createOrder",
    GET_ORDER = "getOrder",
    GET_ORDERS = "getOrders",
    GET_ORDER_ITEMS = "getOrderItems",
    GET_STORIES = "getStories",
    CREATE_CART_ITEM = "createCartItem",
    GET_CART_ITEMS = "getCartItems",
    GET_SHIPPING_COSTS ="getShippingCosts",
    GET_PRODUCTS = "getProducts",
    GET_SHIPPING_OPTIONS = "getShippingOptions",
    DELETE_CART_ITEM = "deleteCartItem",
    CREATE_PAYMENT_SESSION = "createPaymentSession",
    GET_PAYMENT_SESSION = "getPaymentSession",
    UPDATE_PAYMENT_STATUS = "updatePaymentStatus",
    UPSCALE_IMAGES = "upscaleImages",
    PUBLISH_BOOK = "publishBook",
    UPDATE_ORDER_STATUS = "updateOrderStatus",
    SET_STORY_PROMPT_SET = "setPromptSet", //1
    SET_STORY_TOPIC = "setTopic", //2
    SET_STORY_ANALYSE_TOPIC = "setAnalyseTopic", //3
    SET_STORY_TITLE_OPTIONS = "setTitleOptions", //4
    SET_STORY_TITLE_CHOICE = "setTitleChoice", //5
    SET_STORY_IDENTIFY_CHARACTERS = "setIdentifyCharacters", //6
    SET_STORY_CHARACTERS = "setCharacters", //7
    SET_STORY_TO_FROM = "setToFrom", //8
    SET_STORY_DEDICATION = "setDedication", //9
    SET_STORY_TEXT = "setText", //10
    SET_STORY_PAGE = "setPageText", //10a
    SET_STORY_PAGE_TEXT_OPTION = "setPageTextOption", //10b
    SET_STORY_IMAGE_PROMPT_SET = "setImagePromptSet", //11
    SET_STORY_CHARACTERS_STYLE = "setCharactersStyle", //12
    SET_STORY_COVER_VISUAL_DESCRIPTIONS = "setCoverVisualDescriptions", //13
    SET_STORY_VISUAL_DESCRIPTIONS = "setVisualDescriptions", //14
    SET_STORY_COVER_IMAGE = "setCoverImages", //13a
    SET_STORY_PAGE_IMAGE = "setPageImages", //14a
    SET_STORY_PAGE_IMAGE_OPTION = "setPageImageOption" //13/14b
}
export enum HttpMethod {
    GET,
    POST,
    PUT,
    DELETE
}

export enum PromptSetKey {
    BABY_BEDTIME = "babyBedtime",
    FAIRYTALE = "fairytale",
    DREAM = "dream"
}

export enum ImagePromptSetKey {
    HAND_DRAWN = "handDrawn",
    ANIME = "anime",
    FAIRYTALE = "fairytale"
}

export const PromptSet : {[key : string] : AiPromptSet<AiPromptSetProps>} = {
    [PromptSetKey.BABY_BEDTIME]: BabyBedtimePromptSet,
    [PromptSetKey.FAIRYTALE]: FairytalesPromptSet,
    [PromptSetKey.DREAM]: DreamPromptSet
}

export const ImagePromptSet : {[key : string] : AiPromptSet<AiImagePromptSetProps>} = {
    [ImagePromptSetKey.HAND_DRAWN]: handDrawn,
    [ImagePromptSetKey.ANIME]: anime,
    [ImagePromptSetKey.FAIRYTALE]: fairytale
}

export enum AlertVariant {
    ERROR = "danger",
    SUCCESS = "success",
    WARNING = "warning",
    PRIMARY = "primary"
}