import { ReactNode, useRef } from "react";
import { Container, Image, Row } from "react-bootstrap";
import logger from "../../libs/errorLib";
import { useCalculatedDimensions } from "../../hooks/useCalculatedDimensions";

export type ResponsiveImageProps = {
    originalHeight : number,
    originalWidth : number,
    maxHeight? : number,
    url : string,
    overlay? : ReactNode,
    className? : string,
    containerClassName? : string,
    onError? : (e : unknown) => void
}

export default function ResponsiveImage({originalHeight, originalWidth, url, overlay, className, containerClassName, onError, maxHeight} : ResponsiveImageProps){

    const componentRef = useRef<HTMLDivElement>(null);
    const {height, width} = useCalculatedDimensions(componentRef, originalHeight, originalWidth, maxHeight);

    return(
        <Container ref={componentRef} className={`${(containerClassName ? containerClassName : "") + " text-center p-0"}`}>
            <Image src={url}
                height={height ? height + "px" : 0}
                width={width ? width + "px" : 0}
                onError={(e) => {
                    if(onError) onError(e);
                    logger.debug("Error loading image");
                }}
                className={className}
            />
            {overlay && (
                <Row style={{width: '100%', height: height + 'px', position: 'absolute', top: '0'}} className="text-center align-items-center p-0 m-0">
                    {overlay}
                </Row>
            )}
        </Container>
    )
}