import queryString from "query-string";
import { ReactElement } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { useAuthenticatedState } from "../../hooks/useAuthenticatedState";
import Navbar from "../nav/NavBar";
import NavControls from "../nav/NavControls";

export default function SignupRoute({
  children, hideNavBar
}: {
  children: ReactElement, hideNavBar? : boolean
}): ReactElement {
  const { pathname, search } = useLocation();
  const { isAuthenticated } = useAuthenticatedState();
  const location = useLocation();
  const redirect = queryString.parse(location.search).redirect;

  if (!isAuthenticated) {
    return(
        <>
        <Navbar navComponent={<NavControls/>} hidden={hideNavBar === undefined ? false : hideNavBar}/>
        {children}
        </>
    );
  }else{
    return (
        <Navigate replace to={(redirect === "" || redirect === null || redirect === undefined) ? "/" : redirect as string} />
    );
  }
}