import { UserError } from "@fableous/core/model/BasicError";
import {Modal} from "react-bootstrap";
import { isDev, isForceShowError } from "../../libs/sentryLib";
import { EnhancedButton } from "../buttons/EnhancedButton";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default function ErrorModal({error, userAckFnc} : {error : any | undefined, userAckFnc : (() => void) | undefined}) {

    return (
        <Modal
            show={error!==null}
            onHide={userAckFnc}
            backdrop="static"
            keyboard={false}
        >
            {error !== undefined && (
                <>
                <Modal.Header className={(error instanceof UserError) ? "container-secondary" : "container-primary"} closeButton>
                    <Modal.Title>{(error instanceof UserError) ? "Whoops!" : "Error"}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {error !== undefined && (error instanceof UserError || isDev() || isForceShowError()) ?
                        error && (error?.data) ? (error.data.message) : ((error as Error).message)
                     : (
                        "We're sorry, but there's been an error. Please refresh this page and try again."
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <EnhancedButton variant="secondary" onClick={userAckFnc}>
                        Close
                    </EnhancedButton>
                </Modal.Footer>
                </>
            )}
        </Modal>
    );
}