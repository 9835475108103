import React from 'react'
import ReactDOM from 'react-dom/client'
import { BrowserRouter as Router } from 'react-router-dom'
import App from './App'
import config from './config'
import "bootstrap/dist/css/bootstrap.min.css";
import './index.scss'
import { Amplify } from "aws-amplify";
import { initSentry } from './libs/sentryLib'

initSentry();

Amplify.configure({
  Auth: {
    Cognito: {
      userPoolId: config.cognito.USER_POOL_ID,
      identityPoolId: config.cognito.IDENTITY_POOL_ID,
      userPoolClientId: config.cognito.APP_CLIENT_ID,
    }
  },
  Storage: {
    S3: {
      region: config.s3.REGION,
      bucket: config.s3.BUCKET
    }
  },
  API: {
    REST: {
      "api": {
        endpoint: config.apiGateway.URL,
        region: config.apiGateway.REGION,
      },
    },
  },
}, {
  Storage: {
    S3: {
      prefixResolver: async() => { return "" }
    }
  }
});

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <Router>
      <App />
    </Router>
  </React.StrictMode>,
)
