import { Component, ReactNode } from "react";
import { logError } from "../../libs/sentryLib";

type ErrorBoundaryProps = {
  children?: ReactNode; // Typing children as ReactNode (optional)
}

export default class ErrorBoundary extends Component<ErrorBoundaryProps, {hasError : boolean}> {
  state = { hasError: false };

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error : Error) {
    logError(error);
  }

  render() {
    return this.state.hasError ? (
      <div className="ErrorBoundary text-center">
        <h3>Sorry there was a problem loading this page. Please refresh this page or try again later</h3>
      </div>
    ) : (
      this.props.children
    );
  }
}