import { SerializableError } from "../util/Serialization";

Error.stackTraceLimit = 100;
export class BasicError implements SerializableError {
    className: string = "BasicError";
    cause?: Error;

    constructor(name : string, message : string, cause? : Error, stack? : string){

        this.name = this.className + "-" + name;
        this.message = message;
        if(cause)this.cause = cause;
        if(stack) this.stack;
        if (Error.captureStackTrace) {
            Error.captureStackTrace(this);
        }
    }
    name: string;
    message: string;
    stack?: string | undefined;

}

export class UserError extends BasicError {

}

export class UserNotConfirmedError extends UserError {

}

export class UsernameAlreadyExistsError extends UserError {

}

export class RecordNotFoundError extends BasicError {
    
}

export class UnavailableLockError extends BasicError {
    
}