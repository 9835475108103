import  {ReactElement, useEffect } from "react";
import { useAuthenticatedState } from "../../hooks/useAuthenticatedState";
import Navbar from "../nav/NavBar";
import NavControls from "../nav/NavControls";

export type HomeRouteProps = {
  unauthenticatedComponent : ReactElement,
  authenticatedComponent : ReactElement,
  hideNavBarUnauth? : boolean,
  hideNavBarAuth? : boolean
}

export default function HomeRoute({ unauthenticatedComponent, authenticatedComponent, hideNavBarUnauth, hideNavBarAuth } : HomeRouteProps) {
  const { isAuthenticated } = useAuthenticatedState();
  //const { setShowLoginNav } = useAppContext();
  //usePageTitle(title);

  useEffect(() => {
    //setShowLoginNav(showLoginNav);
  });

  if(isAuthenticated){
    return (
      <>
        <Navbar navComponent={<NavControls/>} hidden={hideNavBarAuth === undefined ? false : hideNavBarAuth}/>
        {authenticatedComponent}
      </>
    );
  }else{
    return (
      <>
        <Navbar navComponent={<NavControls/>} hidden={hideNavBarUnauth === undefined ? true : hideNavBarUnauth}/>
        {unauthenticatedComponent}
      </>
    );
  }
}