export function isSubclassOf(subclass: any, parentClass: any): boolean {
    // Walk up the prototype chain to check for inheritance
    while (subclass) {
        if (subclass === parentClass) return true;
        subclass = Object.getPrototypeOf(subclass);
    }
    return false;
}

export const filter = ( obj : object, predicate : Function) => {
    const result = {};
	let key;

    for (key in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, key) && predicate((obj as any)[key])) {
            (result as any)[key] = (obj as any)[key];
        }
    }

    return result;
};