import { getCurrentUser, fetchAuthSession, signIn as awsSignIn, signOut as awsSignOut, signUp as awsSignUp, resendSignUpCode as awsResendSignUpCode, confirmSignUp as awsConfirmSignUp, SignUpOutput, AuthError, resetPassword as awsResetPassword, confirmResetPassword as awsConfirmResetPassword} from "aws-amplify/auth";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { awsErrorInterpreter } from "../util/awsErrors";
import { makeObservable } from "./hooks";
import { AuthSession } from "@aws-amplify/core/dist/esm/singleton/Auth/types";


export const isAuthenticatedStateStore = makeObservable<boolean>(false);
export const isAuthenticatingStateStore = makeObservable<boolean>(true);
export const authSessionStore = makeObservable<AuthSession | undefined>(undefined);
export const authTokenStore = makeObservable<{token : string, userRegion : string, userId : string} | undefined>(undefined);

export type authenticatedState = {
    authSession : AuthSession | undefined,
    userId : string | undefined,
    userRegion : string | undefined,
    authToken: string | undefined,
    signIn : (email : string, password : string) => Promise<void>,
    signOut : () => Promise<void>, isAuthenticated : boolean, isAuthenticating : boolean,
    signUp : (email : string, password : string) => Promise<SignUpOutput>,
    resendConfirmationCode: (email : string) => Promise<void>,
    resetPassword : (email : string) => Promise<void>,
    confirmResetPassword : (email : string, confirmationCode : string, newPassword : string) => Promise<void>,
    confirmSignUp: (email : string, confirmationCode : string) => Promise<void>
}

export function useAuthenticatedState(): authenticatedState {
    const [isAuthenticated, setIsAuthenticated] = useState<boolean>(isAuthenticatedStateStore.get());
    const [isAuthenticating, setIsAuthenticating] = useState<boolean>(isAuthenticatingStateStore.get());
    const [authSession, setAuthSession] = useState<AuthSession | undefined>(authSessionStore.get());
    const [authToken, setAuthToken] = useState<{ token: string; userRegion: string; userId: string; } | undefined>(authTokenStore.get());
    const nav = useNavigate();

    useEffect(() => {
        async function onLoad() {
            await checkCredentials();
        }
        const retFnc1 = isAuthenticatedStateStore.subscribe(setIsAuthenticated);
        const retFnc2 = isAuthenticatingStateStore.subscribe(setIsAuthenticating);
        const retFnc3 = authSessionStore.subscribe(setAuthSession);
        const retFnc4 = authTokenStore.subscribe(setAuthToken);
        onLoad();
        return () => {
            retFnc1();
            retFnc2();
            retFnc3();
            retFnc4();
        };
    }, []);

    async function checkCredentials() {
        try {
            await getCurrentUser();
            const authSession = await fetchAuthSession();
            authSessionStore.set(authSession);
            const userRegion: string = authSession.identityId?.split(":")[0] as string;
            const userId: string = authSession.identityId as string;
            authTokenStore.set({ token: authSession.tokens?.idToken?.toString() as string, userRegion, userId });
            isAuthenticatedStateStore.set(true);
        } catch (e) {
            authSessionStore.set(undefined);
            authTokenStore.set(undefined);
            isAuthenticatedStateStore.set(false);
        }
        isAuthenticatingStateStore.set(false);
    }

    async function signIn(email : string, password: string): Promise<void> {
        try {
            const signInResult = await awsSignIn({ username: email, password });
            if(!signInResult.isSignedIn && signInResult.nextStep.signInStep === "CONFIRM_SIGN_UP") throw new AuthError({name: "UserNotConfirmedException", message: "User is not confirmed"});
            isAuthenticatedStateStore.set(true);
        } catch (e: unknown) {
            isAuthenticatedStateStore.set(false);
            throw awsErrorInterpreter(e);
        }
    }

    async function signOut() {
        try {
            await awsSignOut();
        } catch (e) {
            throw awsErrorInterpreter(e);
        }
        isAuthenticatedStateStore.set(false);
        nav("/login");
    }

    async function signUp(email: string, password: string) {
        try {
            return await awsSignUp({
                username: email, password
            });
        } catch (e) {
            throw awsErrorInterpreter(e);
        }
    }

    async function resendConfirmationCode(email: string) {
        try {
            await awsResendSignUpCode({ username: email });
        } catch (e) {
            throw awsErrorInterpreter(e);
        }
    }

    async function confirmSignUp(email : string, confirmationCode : string){
        try{
            await awsConfirmSignUp({username: email, confirmationCode});
        }catch(e){
            throw awsErrorInterpreter(e);
        }
    }

    async function resetPassword(email : string){
        try{
            await awsResetPassword({
                username: email
            });
        }catch(e){
            throw awsErrorInterpreter(e);
        }
    }

    async function confirmResetPassword(email : string, confirmationCode : string, newPassword : string){
        try{
            await awsConfirmResetPassword({
                username: email,
                newPassword,
                confirmationCode
            });
        }catch(e){
            throw awsErrorInterpreter(e);
        }
    }

    return {
        authSession,
        userId: authToken?.userId,
        userRegion: authToken?.userRegion,
        authToken: authToken?.token,
        signIn,
        signOut,
        signUp,
        resendConfirmationCode,
        resetPassword,
        confirmResetPassword,
        confirmSignUp,
        isAuthenticated,
        isAuthenticating
    };
}
