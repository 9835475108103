import { Alert } from "react-bootstrap";

export type InfoAlertProps = {
    message : string,
    variant : string,
    alertDismissedFunction : () => void,
    [key : string] : unknown
}

export default function InfoAlert({message, variant, alertDismissedFunction, ...props} : InfoAlertProps){
    return(
        <>
            {message ? (
                <Alert variant={variant} dismissible onClose={alertDismissedFunction} {...props}>
                    {message}
                </Alert>
                ) : null
            }
        </>
    )
}