import * as Sentry from "@sentry/react";
import config from "../config";
import { LogLevel } from "@fableous/core/model/Enums";

export const stage = config.general.STAGE;
let errorCount = 0;

export function isForceShowError(){
  return window ? (new URL(window.location.toString())).searchParams.get("showErrors") : false;
}

export function isLocal(){
  return stage === "dev" && window && window.location.host.startsWith("localhost");
}

export function isDev(){
  return stage === "dev";
}

export function initSentry() {
  if (isLocal()) {
    return;
  }
  Sentry.init({
    dsn: config.general.SENTRY_DSN,
    environment: config.general.STAGE,
    tracePropagationTargets: ["localhost", "uat.fableous.co", "fableous.co", "www.fabelous.co"],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}

export function logError(error : unknown, errorInfo? : unknown) {

  if (isLocal() || errorCount > 50) {
    console.error(error, errorCount);
    return;
  }
  errorCount += 1;

  Sentry.withScope((scope) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    errorInfo && scope.setExtras(errorInfo as any);
    Sentry.captureException(error);
  });
}

export function logMessage(message : unknown, level : LogLevel){
  if(isLocal()){
    if(level === LogLevel.DEBUG) console.debug(message);
    if(level === LogLevel.INFO) console.info(message);
    if(level === LogLevel.WARN) console.warn(message);
  }
}
