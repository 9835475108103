import { FC } from "react";
import {Modal} from "react-bootstrap";
import { UserMessage } from "../../libs/contextLib";
import { EnhancedButton } from "../buttons/EnhancedButton";

export type InfoModalProps = {
    message? : UserMessage,
    Body? : FC<unknown>,
    cancelCallback? : () => void,
    acceptCallback? : () => void
}

export default function InfoModal({message, Body, cancelCallback, acceptCallback} : InfoModalProps) {

    return (
        <Modal
            show={message!==null}
            onHide={cancelCallback}
            backdrop="static"
            keyboard={false}
        >
            {message && !Body && (
                <>
                <Modal.Header className={message.variant === "warning" ? "container-secondary" : "container-complement"} closeButton>
                    <Modal.Title>{message.title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {message.body}
                </Modal.Body>
                <Modal.Footer>
                    {message.cancelButtonText && (
                        <EnhancedButton variant="secondary" onClick={cancelCallback}>
                            {message.cancelButtonText}
                        </EnhancedButton>
                    )}
                    <EnhancedButton variant={message.variant === "warning" ? "warning" : "secondary"} onClick={acceptCallback}>
                        {message.buttonText}
                    </EnhancedButton>
                </Modal.Footer>
                </>
            )}
            {!message && Body && (<Body />)}
        </Modal>
    );
}