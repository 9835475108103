import Routes from "./Routes";
import { useAuthenticatedState } from "./hooks/useAuthenticatedState";
import ErrorBoundary from "./components/error/ErrorBoundary";
import ErrorManager from "./components/error/ErrorManager";
import InfoManager from "./components/info/InfoManager";

function App() {
  const {isAuthenticating} = useAuthenticatedState();

  return (
    !isAuthenticating ? (
      <div>
        <ErrorBoundary>
          <ErrorManager>
            <InfoManager>
              <Routes />
            </InfoManager>
          </ErrorManager>
        </ErrorBoundary>
      </div>
    ) : (
      <></>
    )
  );
}

export default App;