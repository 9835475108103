import {ReactNode, useCallback, useEffect, useRef, useState} from "react";
import InfoModal from "./InfoModal";
import {InfoContext, UserAlert, UserMessage, UserMessageModal} from "../../libs/contextLib";
import InfoAlert from "./InfoAlert";
import { useLocationEffect } from "../../hooks/hooks";

export default function InfoManager({children} : {children : ReactNode}){

    const pathName : string | undefined = useLocationEffect();
    const resolve = useRef<(value?: {clickedOK : boolean}) => void>();
    const reject = useRef<(reason : unknown) => void>();
    const [message, setMessage] = useState<UserMessage | undefined>(undefined);
    const [messageModal, setMessageModal] = useState<UserMessageModal | undefined>(undefined);
    const [userAlert, setUserAlert] = useState<UserAlert | undefined>(undefined);

    const resetMessageState = useCallback(() => {
        setMessage(undefined);
        setMessageModal(undefined);
    },[]);

    useEffect(() => {
        setUserAlert(undefined);
        setMessage(undefined);
        setMessageModal(undefined);
    }, [pathName]);

    const handleCancel = useCallback(() => {
        if(message?.cancelCallback) message.cancelCallback();
        if(messageModal?.cancelCallback) messageModal.cancelCallback();
        if(resolve.current) resolve.current({clickedOK: false});
        resetMessageState();
    },[message, messageModal, resetMessageState]);

    const handleCallback = useCallback(() => {
        if(message?.callback) message.callback();
        if(messageModal?.callback) messageModal.callback();
        if(resolve.current) resolve.current({clickedOK: true});
        resetMessageState();
    },[message, messageModal, resetMessageState]);

    const setUserMessage = useCallback((userMessage : UserMessage) => {
        const promise = new Promise((res, rej) => {
            resolve.current = res;
            reject.current = rej;
        });
        setMessage(userMessage);
        return promise;
    },[]);

    const setUserMessageModal = useCallback((userMessageModal : UserMessageModal) => {
        const promise = new Promise((res, rej) => {
            resolve.current = res;
            reject.current = rej;
        });
        setMessageModal(userMessageModal);
        return promise;
    },[]);

    return(
        <InfoContext.Provider value={{setUserMessage, setUserAlert, setUserMessageModal}}>
            {userAlert && <InfoAlert className="mt-3" message={userAlert.message} alertDismissedFunction={() => setUserAlert(undefined)} variant={userAlert.variant}/>}
            {message && <InfoModal message={message} cancelCallback={handleCancel} acceptCallback={handleCallback}></InfoModal>}
            {messageModal && <InfoModal Body={() => messageModal.component({...messageModal.props, callback: handleCallback, cancelCallback: handleCancel})} cancelCallback={handleCancel} acceptCallback={handleCallback}></InfoModal>}
            {children}
        </InfoContext.Provider>
    )
}