const config = {
    // Backend config
    s3: {
      REGION: import.meta.env.VITE_REGION,
      BUCKET: import.meta.env.VITE_BUCKET,
    },
    apiGateway: {
      REGION: import.meta.env.VITE_REGION,
      URL: import.meta.env.VITE_API_URL,
    },
    wsApiGateway : {
        REGION: import.meta.env.VITE_REGION,
        URL: import.meta.env.VITE_WS_API_URL
    },
    cognito: {
      REGION: import.meta.env.VITE_REGION,
      USER_POOL_ID: import.meta.env.VITE_USER_POOL_ID,
      APP_CLIENT_ID: import.meta.env.VITE_USER_POOL_CLIENT_ID,
      IDENTITY_POOL_ID: import.meta.env.VITE_IDENTITY_POOL_ID,
    },
    general: {
      STAGE: import.meta.env.VITE_STAGE,
      SENTRY_DSN: import.meta.env.VITE_SENTRY_DSN
    }
  };

  export default config;