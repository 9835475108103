import { BasicError, UserError, UsernameAlreadyExistsError, UserNotConfirmedError } from "@fableous/core/model/BasicError";
import { SerializableError } from "@fableous/core/util/Serialization";
import { AuthError } from "aws-amplify/auth";

export const awsErrorInterpreter = (e : unknown) : SerializableError => {
    if(e instanceof AuthError){
        switch (e.name){
            case "UserNotConfirmedException":
                return new UserNotConfirmedError(e.name, e.message, e);
            case "UsernameExistsException":
                return new UsernameAlreadyExistsError(e.name, e.message, e);
            case "UserNotAuthorizedException":
            case "NotAuthorizedException":
            case "UserNotFoundException":
            case "TooManyRequestsException":
            case "CodeDeliveryFailureException":
            case "CodeMismatchException":
            case "AliasExistsException":
            case "ExpiredCodeException":
            case "LimitExceededException":
            case "TooManyFailedAttemptsException":
                return new UserError(e.name, e.message, e);
            default:
                return new BasicError(e.name, e.message, e);
        }
    }else if(e instanceof Error){
        return new BasicError(e.name, e.message, e);
    }else{
        return new BasicError("Unknown error", "Unknown error");
    }
}