import { ReactNode, useRef } from "react";
import {Container, Navbar as BsNavBar} from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import { useHover } from "../../hooks/useHover";
import FableousLogo from "../graphic/FableousLogo";
import FableousTitle from "../graphic/FableousTitle";
import "./nav-bar.scss";

export default function Navbar({navComponent, hidden} : {navComponent : ReactNode, hidden? : boolean}){

    const navRef = useRef<HTMLDivElement>(null);
    const divRef = useRef<HTMLDivElement>(null);
    const [hoveringNav, recentHoveringNav] = useHover(navRef);
    const [hoveringDiv] = useHover(divRef);

    return (
        <>
            <div
                className="hover-trigger"
                ref={divRef}
            />
            <BsNavBar ref={navRef} fixed="top" collapseOnSelect expand="md" className={`container-outline-shadow hover-navbar${hoveringDiv || hoveringNav || recentHoveringNav || !hidden ? ' show' : ''}`}>
                <Container>
                    <LinkContainer to="/" className="link-no-decoration mt-0">
                        <BsNavBar.Brand>
                            <FableousLogo maxHeight={30}/>
                        </BsNavBar.Brand>
                    </LinkContainer>
                    <LinkContainer to="/" className="link-no-decoration mt-0">
                        <BsNavBar.Brand>
                            <FableousTitle maxHeight={20}/>
                        </BsNavBar.Brand>
                    </LinkContainer>
                    <BsNavBar.Toggle aria-controls="navbar" className="mt-1"/>
                    <BsNavBar.Collapse id="navbar" className="justify-content-end">
                        {navComponent}
                    </BsNavBar.Collapse>
                </Container>
            </BsNavBar>
        </>
    )
}