import {ReactNode, useCallback, useEffect, useState} from "react";
import ErrorModal from "./ErrorModal";
import {ErrorContext} from "../../libs/contextLib";
import logger from "../../libs/errorLib";
import { UserError } from "@fableous/core/model/BasicError";


export default function ErrorManager({children} : {children : ReactNode}){

    const [error, setError] = useState<unknown | undefined>(undefined);
    const [userAckFnc, setUserAckFnc] = useState<() => void>();

    const resetErrorState = useCallback(() => {
        setError(undefined);
    },[]);

    useEffect(() => {
        setUserAckFnc(function(){return resetErrorState});
    },[resetErrorState]);

    useEffect(() => {
        if(error && !(error instanceof UserError)){
            logger.error(error);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[error]);

    return(
        <ErrorContext.Provider value={{setError}}>
            <>
                {error && <ErrorModal error={error} userAckFnc={userAckFnc}></ErrorModal>}
                {children}
            </>
        </ErrorContext.Provider>
    )
}