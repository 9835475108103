import { ReactElement } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { useAuthenticatedState } from "../../hooks/useAuthenticatedState";
import Navbar from "../nav/NavBar";
import NavControls from "../nav/NavControls";

export default function AuthenticatedRoute({
  children, hideNavBar
}: {
  children: ReactElement, hideNavBar? : boolean
}): ReactElement {
  const { pathname, search } = useLocation();
  const { isAuthenticated } = useAuthenticatedState();

  if (!isAuthenticated) {
    return <Navigate to={`/signup?redirect=${pathname}${search}`} />;
  }

  return (
    <>
      <Navbar navComponent={<NavControls/>} hidden={hideNavBar === undefined ? false : hideNavBar}/>
      {children}
    </>
  );
}