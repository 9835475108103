import { ReactNode, useRef } from "react";
import { Container, Row } from "react-bootstrap";
import { useCalculatedDimensions } from "../../hooks/useCalculatedDimensions";

export type ResponsiveContainerProps = {
    originalHeight : number,
    originalWidth : number,
    overlay? : ReactNode,
    visible? : boolean,
    innerContainerClassName? : string,
    containerClassName? : string,
    children? : ReactNode
}

//Not currently working properly
export default function ResponsiveContainer({originalHeight, originalWidth, overlay, containerClassName, innerContainerClassName, children} : ResponsiveContainerProps){

    const componentRef = useRef<HTMLDivElement>(null);
    const {height, width} = useCalculatedDimensions(componentRef, originalHeight, originalWidth);

    return(
        <Container ref={componentRef} className={`${(containerClassName ? containerClassName : "") + " text-center p-0"}`}>
            <Container style={{width: width + 'px', height: height + 'px'}} className={`${innerContainerClassName || ""}`}>
                {children}
            </Container>
            {overlay && (
                <Row style={{width: '100%', height: height + 'px', position: 'absolute', top: '0'}} className="text-center align-items-center p-0 m-0">
                    {overlay}
                </Row>
            )}
        </Container>
    )
}