import { ObjectPKInput, ObjectSKInput } from "../aws/DynamoDbApiTypes";
import { filter } from "../util/Helpers";
import { meanAverage } from "../util/Math";
import Serializer, { Serializable } from "../util/Serialization";
import { BasicError } from "./BasicError";
import { User } from "./User";

export type DynamoKey = {PK : string, SK : string};
export type UserOwnedDynamoKey = DynamoKey;
export type DynamoObject = {className : string, GSI1PK? : string, GSI1SK? : string, GSI2PK? : string, GSI2SK? : string} & DynamoKey & BaseDynamoClass;
export abstract class BaseDynamoClass implements Serializable {

    counts : {[key : string] : number};
    countChanges : {[key : string] : number};
    parentCountChanges : {[key : string] : number};
    dirty : boolean;
    dirtyFields: string[];
    _createdDate: number;
    _updatedDate: number;
    className: string;
    _createdBy?: DynamoKey;
    _updatedBy?: DynamoKey;
    //Lock key is to avoid short term race conditions occurring
    _lockKey?: string;
    //Lock owner is to allow longer running operations to hold lock on object. (Datetime in ms that the lock expires)
    _lockOwner? : number;
    _lockExpires ? : number;
    _status : number = 0;

    constructor(){
        this.className = "BaseDynamoClass";
        this.counts = {};
        this.countChanges = {};
        this.parentCountChanges = {};
        this.dirty = false;
        this.dirtyFields = [];
        this._createdDate = Date.now();
        this._updatedDate = this.createdDate;
    }
    getIgnoreFields(viewIndex: number): string[]{
        return [];
    };

    static splitPrefix(key : string) : string{
        return key.split("#")[0];
    }
    abstract getPKPrefix() : string;
    abstract getSKPrefix(props : (object | undefined)) : string;
    isPermissionCheckNeeded(){
        return true;
    }
    abstract getPK() : string;
    abstract getSK() : string;
    abstract getIndexPermissionKey(indexNumber : number, item : BaseDynamoClass) : DynamoKey;
    getKey() : DynamoKey {
        return {
            PK: this.getPK(),
            SK: this.getSK()
        }
    }
    getKeyString() : string {
        return this.getPK() + "#" + this.getSK();
    }
    abstract getParentKey() : (DynamoKey | undefined)
    abstract getParentKeyInput() : (ObjectPKInput | undefined)
    abstract getParentTableName() : (string | undefined);
    abstract generateKey(input : any) : DynamoKey;
    abstract generatePK(input : ObjectPKInput) : string;
    abstract generateGSIPK(index : number, key : ObjectPKInput) : string;
    abstract generateGSISK(index : number, key : (ObjectSKInput | undefined)) : string;
    abstract getGlobalIndices() : object;
    getDynamoObject() : DynamoObject {
        const obj : DynamoObject = {
            ...this,
            className: this.className,
            ...this.getGlobalIndices(),
            PK: this.getPK(),
            SK: this.getSK()
        }
        return obj;
    }
    getDynamoObjectForUpdate() : any {
        const obj : any = {
            ...this.getGlobalIndices()
        };
        if(this.dirtyFields.length > 0) this.markFieldDirty("_updatedDate");
        this.dirtyFields.forEach(fieldName => {
            if(!Object.prototype.hasOwnProperty.call(this, fieldName)) throw new BasicError("Incorrect config", `Field (${fieldName})set as dirty but doesn't exist on parent object (${this.constructor.name})`);
            obj[fieldName] = (this as any)[fieldName]
        });
        return obj;
    }
    markFieldDirty(fieldName : string) : void {
        this._updatedDate = Date.now();
        if(!this.dirtyFields.includes(fieldName)) this.dirtyFields.push(fieldName);
        this.dirty = true;
    }
    markClean() : void {
        this.dirty = false;
        this.dirtyFields = [];
        this.countChanges = {};
        this.parentCountChanges = {};
    }
    isDirty() : boolean {
        return this.dirty;
    }
    /**
     * @param {number} createdDate
     */
    set createdDate(createdDate){
        this._createdDate = createdDate;
        this.markFieldDirty("_createdDate");
    }
    get createdDate(){
        return this._createdDate;
    }
    set createdBy(createdBy : User){
        this._createdBy = createdBy.getKey();
        this.markFieldDirty("_createdBy");
    }
    get createdBy() : any {
        return this._createdBy;
    }
    set lockKey(lockKey){
        this._lockKey = lockKey;
        this.markFieldDirty("_lockKey");
    }
    get lockKey(){
        return this._lockKey;
    }
    set lockOwner(lockOwner){
        this._lockOwner = lockOwner;
        this.markFieldDirty("_lockOwner");
    }
    get lockOwner(){
        return this._lockOwner;
    }
    set lockExpires(lockExpires){
        this._lockExpires = lockExpires;
        this.markFieldDirty("_lockExpires");
    }
    get lockExpires(){
        return this._lockExpires;
    }
    set updatedDate(updatedDate){
        this._updatedDate = updatedDate;
        this.markFieldDirty("_updatedDate");
    }
    get updatedDate(){
        return this._updatedDate;
    }
    set updatedBy(updatedBy : User){
        this._updatedBy = updatedBy.getKey();
        this.markFieldDirty("_updatedBy");
    }
    get updatedBy() : any{
        return this._updatedBy;
    }
    set status(status : number){
        //If no status change then exit
        if(this.status === status) return;
        if(!this.validateStatus(status)) return;
        //Check if parent counts already have a status set. If they do, subtract 1. If they don't and status has been set, then set to -1.
        if(this.parentCountChanges && (this.parentCountChanges as any)[this.className + this._status]){
            (this.parentCountChanges as any)[this.className + this._status] -= 1;
        }else if(this.parentCountChanges && (this.status || this.status === 0)){
            (this.parentCountChanges as any)[this.className + this._status] = -1;
        }
        //Set status
        this._status = status;
        //Now increase or set counts
        if(this.parentCountChanges && (this.parentCountChanges as any)[this.className + this._status]){
            (this.parentCountChanges as any)[this.className + this._status] += 1;
        }else if(this.parentCountChanges){
            (this.parentCountChanges as any)[this.className + this._status] = 1;
        }
        this.markFieldDirty("_status");
    }
    get status() : number{
        return this._status;
    }
    validateStatus(status : number) : boolean{
        //Override this method - 1 argument passed, status
        return true;
    }
    changeCount(key : string, value : number, includeOnParent : boolean = true) : void {
        if(value === 0) return;
        if((this.counts as any)[key]){
            (this.counts as any)[key] += value;
        }else{
            (this.counts as any)[key] = value;
        }
        if((this.countChanges as any)[key]){
            (this.countChanges as any)[key] += value;
        }else{
            (this.countChanges as any)[key] = value;
        }
        if(includeOnParent){
            if((this.parentCountChanges as any)[key]){
                (this.parentCountChanges as any)[key] += value;
            }else{
                (this.parentCountChanges as any)[key] = value;
            }
        }
    }
    switchCounts(key : string, value : number, includeOnParent : boolean = true) : void {
        this.changeCount(key, value, includeOnParent);
        Object.entries((this.counts as any)).forEach(([countKey]) => {
            if(countKey !== key) this.setCount(countKey, 0, includeOnParent)
        });
    }
    changeCounts(changes : object, includeOnParent : boolean = true) : void{
        if(Object.keys(changes).length === 0) return;
        Object.entries(changes).forEach(([key, value]) => this.changeCount(key, value, includeOnParent));
    }
    setCount(key : string, value : number, includeOnParent = true){
        if((this.counts as any)[key]){
            if((this.countChanges as any)[key]) {
                (this.countChanges as any)[key] += value - (this.counts as any)[key];
            }else{
                (this.countChanges as any)[key] = value - (this.counts as any)[key];
            }
        }else{
            (this.countChanges as any)[key] = value;
        }
        if((this.countChanges as any)[key] === 0) delete (this.countChanges as any)[key];
        (this.counts as any)[key] = value;
        if(includeOnParent){
            (this.parentCountChanges as any)[key] = (this.countChanges as any)[key];
            if((this.parentCountChanges as any)[key] === 0) delete (this.parentCountChanges as any)[key];
        }
    }
    setCounts(counts : object, includeOnParent : boolean = true) : void {
        if(Object.keys(counts).length === 0) return;
        Object.entries(counts).forEach(([key, value]) => this.setCount(key, value, includeOnParent));
    }
    hasCountChanges() : boolean {
        return Object.keys((this.countChanges as any)).length > 0;
    }
    getCountChanges() : (object | undefined) {
        return this.countChanges;
    }
    getCountByKey(key : string, defaultValue = 0) : number {
        return (this.counts as any)[key] ? (this.counts as any)[key] : defaultValue;
    }
    getAverageByKeys(keyNumerator : string, keyDenominator : string, decimals = 2, NaNReplacement : number = 0) : number {
        return meanAverage(this.getCountByKey(keyNumerator), this.getCountByKey(keyDenominator), decimals, NaNReplacement);
    }
    getCountByKeyFilter(fnc : (key : string) => boolean) : number {
        return Object.entries<number>((this.counts as any)).reduce((prevValue : number, [key, val] : [string, number]) => fnc(key) ? prevValue + val : prevValue, 0);
    }
    getParentCountChanges() : (object | null) {
        if(this.parentCountChanges) return filter(this.parentCountChanges, (val : number) => val !== 0);
        return null;
    }
    abstract getColumnsForExport() : Array<string>;
    abstract getObjectForExport() : object;
    isLocked() : boolean {
        return this.lockExpires !== undefined && this.lockExpires > Date.now();
    }
    hasLock(): boolean {
        return this.isLocked() && this.lockOwner === this.lockExpires;
    }
}

