import { Serializable } from "@fableous/core/util/Serialization";
import { SocketMessage } from "@fableous/core/controller/ApiMessageTypes";

import {useState, useEffect, useRef, ChangeEventHandler, ChangeEvent} from "react";
import { ActionVerb } from "@fableous/core/model/Enums";

//https://stackoverflow.com/questions/53451584/is-it-possible-to-share-states-between-components-using-the-usestate-hook-in-r

export function makeObservable<T>(target:T) {

    let listeners: ((val: T) => void)[] = [];
    let value = target;

    function get() : T {
      return value;
    }

    function set(newValue:T) : void {
      if (value === newValue) return;
      value = newValue;
      listeners.forEach((l) => l(value));
    }

    function subscribe(listenerFunc:((val: T) => void)) {
      listeners.push(listenerFunc);
      return () => unsubscribe(listenerFunc); // will be used inside React.useEffect
    }

    function unsubscribe(listenerFunc:((val: T) => void)) {
      listeners = listeners.filter((l) => l !== listenerFunc);
    }

    return {
      get,
      set,
      subscribe,
    };
}

export type SocketListener<T extends Serializable> = {
    onMessage : (message : SocketMessage<T>) => void,
    onOpen? : () => void,
    onClose? : (code : number, reason : string) => void,
    onError : (error : SocketMessage<T> | Event) => void,
    onPing? : (data : Buffer) => void,
    onPong? : (data : Buffer) => void,
    actionVerb? : ActionVerb | ActionVerb[]
}

export type SocketMessageOptions = {
  userRegion? : string,
  userId? : string,
  pathParameters? : object,  //For passing onto lambda function (like a GET request)
  timeout? : number, //Timeout to apply for each call (resets whenever any data is received)
  completeOnResponse? : boolean, //Should the call complete on the first "response", or wait for finish message
  startingProgress? : number //The min the call should contribute to the progress bar, defaults to 0
  endingProgress? : number //The max the call should contribute to the progress bar, defaults to 100
  expectedMessageQuantity? : number
}

export type SocketMessageProps<T> = {
  actionVerb : ActionVerb,
  data? : T,
  options?: SocketMessageOptions,
}

type FieldsType = {
    [key: string | symbol]: string;
  }

export function useFormFields(
    initialState: FieldsType
  ): [FieldsType, ChangeEventHandler] {
    const [fields, setValues] = useState(initialState);

    return [
      fields,
      function (event: ChangeEvent<HTMLInputElement>) {
        setValues({
          ...fields,
          [event.target.id]: event.target.value,
        });
        return;
      },
    ];
  }

  export function useInterval(callback : () => void, delay : number | undefined, id : string = ""){
    const savedCallback = useRef<() => void>();
    const [count, setCount] = useState(0);

    useEffect(() => {
      savedCallback.current = callback;
    }, [callback]);

    useEffect(() => {
      function tick(){
        if(savedCallback.current) savedCallback.current();
        setCount(prevCount => prevCount + 1);
      }
      if(delay !== undefined){
        const id = setInterval(tick, delay)
        return () => {
          clearInterval(id);
        };
      }
    }, [delay]);

    return {
      ["count" + id]: count
    }
  }

  export function useScript(url : string, condition = true){
    useEffect(() => {
      let script : HTMLScriptElement;
      if(condition){
        script = document.createElement('script');
        script.src = url;
        script.async = true;
        document.head.appendChild(script);
      }

      return () => {
        if(script) document.head.removeChild(script);
      }
    }, [url, condition]);
  }

  export function usePageTitle(title : string){
    useEffect(() => {
        document.title = (title && title !== "title") ? title + " | Pitchli" : "Pitchli";
    }, [title]);
  }

  export function useLocationEffect(){
    const [location, setLocation] = useState<Location>();

    useEffect(() => {
      if(window && window.location) setLocation(window.location);
    },[]);

    return location?.pathname;

  }