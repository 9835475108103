import { RefObject, useEffect, useRef, useState } from "react";

export function useHover(ref : RefObject<HTMLDivElement>) {
    const [hovering, setHovering] = useState<boolean>(false);
    const [recentHovering, setRecentHovering] = useState<boolean>(false);
    const leaveTimer = useRef<number | undefined>(undefined);

    useEffect(() => {

        function handleEnter(){
            clearInterval(leaveTimer.current);
            setHovering(true);
            setRecentHovering(true);
        }
        function handleLeave(){
            setHovering(false);
            leaveTimer.current = window.setInterval(() => {
                setRecentHovering(false);
            }, 500);
        }

        if(ref.current){
            ref.current.addEventListener("mouseenter", handleEnter);
            ref.current.addEventListener("mouseleave", handleLeave);
        }
        return(() => {
            ref.current?.removeEventListener("mouseenter", handleEnter);
            ref.current?.removeEventListener("mouseleave", handleLeave);
        })

    },[ref.current]);


    return [hovering, recentHovering];
  }