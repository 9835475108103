import { Nav } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import { useAuthenticatedState } from "../../hooks/useAuthenticatedState";

export default function NavControls(){
    const {isAuthenticated, signOut} = useAuthenticatedState();
    return (
        <Nav activeKey={window.location.pathname} className="container-white-0">
            {isAuthenticated ? (
              <>
                <LinkContainer to="/orders">
                  <Nav.Link>Orders</Nav.Link>
                </LinkContainer>
                <LinkContainer to="/cart">
                  <Nav.Link>Cart</Nav.Link>
                </LinkContainer>
                <Nav.Link onClick={signOut}>Logout</Nav.Link>
              </>
            ) : (
              <>
                <LinkContainer to="/signup">
                  <Nav.Link>Signup</Nav.Link>
                </LinkContainer>
                <LinkContainer to="/login">
                  <Nav.Link>Login</Nav.Link>
                </LinkContainer>
              </>
            )}
        </Nav>
    )
}