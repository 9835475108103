import { RefObject, useEffect, useState } from "react";
import { useWindowDimensions } from "./useWindowDimensions";

export function useDimensions(ref : RefObject<HTMLDivElement>) {
    const windowDimensions = useWindowDimensions();
    const [dimensions, setDimensions] = useState({ width: 0, height: 0 })

    useEffect(() => {
      const observedElement = ref.current; // DOM element to observe
  
      if (observedElement) {
        // Initialize ResizeObserver
        const resizeObserver = new ResizeObserver(entries => {
          for (const entry of entries) {
            const { width, height } = entry.contentRect;
            setDimensions({width, height});
          }
        });
  
        // Start observing the element
        resizeObserver.observe(observedElement);
  
        // Clean up observer on component unmount
        return () => {
          resizeObserver.unobserve(observedElement);
        };
      }
    }, [ref]);

    return {
      window: windowDimensions,
      container: dimensions
    }
  }