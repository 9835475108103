import { Route, Routes } from "react-router-dom";
import HomeRoute from "./components/routes/HomeRoute";
import AuthenticatedRoute from "./components/routes/AuthenticatedRoute"
import SignupRoute from "./components/routes/SignupRoute";
import { Suspense, lazy } from "react";
import LoadingContainer from "./components/layout/LoadingContainer";

const Home = lazy(() => import ('./containers/Home'));
const HomeLander = lazy(() => import ('./containers/HomeLander'));
const Login = lazy(() => import ('./containers/Login'));
const ResetPassword = lazy(() => import ('./containers/ResetPassword'));
const Signup = lazy(() => import ('./containers/signup/Signup'));
const CreateStory = lazy(() => import ('./containers/stories/CreateStory'));
const ViewStory = lazy(() => import ('./containers/stories/ViewStory'));
const StoryWizard = lazy(() => import ('./containers/stories/StoryWizard'));
const CheckoutWizard = lazy(() => import ('./containers/cart/CheckoutWizard'));
const Cart = lazy(() => import ('./containers/cart/Cart'));
const CheckoutReturn = lazy(() => import ('./containers/cart/checkout/CheckoutReturn'));
const Orders = lazy(() => import ('./containers/orders/Orders'));
const Order = lazy(() => import ('./containers/orders/Order'));
const NotFound = lazy(() => import ('./containers/NotFound'));


export default function Links() {
  return (
    <Routes>
      <Route path="/" element={
        <Suspense fallback={<LoadingContainer />}>
          <HomeRoute hideNavBarAuth={false} authenticatedComponent={<Home />} unauthenticatedComponent={<HomeLander />} />
        </Suspense>} />
      <Route path="/login" element={
        <Suspense fallback={<LoadingContainer />}>
          <SignupRoute><Login /></SignupRoute>
        </Suspense>
      }/>
      <Route path="/login/reset" element={
        <Suspense fallback={<LoadingContainer />}>
          <SignupRoute><ResetPassword /></SignupRoute>
        </Suspense>} />
      <Route path="/signup" element={
        <Suspense fallback={<LoadingContainer />}>
          <SignupRoute><Signup /></SignupRoute>
        </Suspense>} />
      <Route path="/stories/create" element={
        <Suspense fallback={<LoadingContainer />}>
          <AuthenticatedRoute><CreateStory /></AuthenticatedRoute>
        </Suspense>} />
      <Route path="/stories/:id" element={
        <Suspense fallback={<LoadingContainer />}>
          <AuthenticatedRoute><ViewStory /></AuthenticatedRoute>
        </Suspense>} />
      <Route path="/stories/:id/edit" element={
        <Suspense fallback={<LoadingContainer />}>
          <AuthenticatedRoute><StoryWizard /></AuthenticatedRoute>
        </Suspense>} />
      <Route path="/cart/checkout" element={
        <Suspense fallback={<LoadingContainer />}>
          <AuthenticatedRoute><CheckoutWizard /></AuthenticatedRoute>
        </Suspense>} />
      <Route path="/cart" element={
        <Suspense fallback={<LoadingContainer />}>
          <AuthenticatedRoute><Cart /></AuthenticatedRoute>
        </Suspense>} />
      <Route path="/cart/complete" element={
        <Suspense fallback={<LoadingContainer />}>
          <AuthenticatedRoute><CheckoutReturn /></AuthenticatedRoute>
        </Suspense>} />
      <Route path="/orders" element={
        <Suspense fallback={<LoadingContainer />}>
          <AuthenticatedRoute><Orders /></AuthenticatedRoute>
        </Suspense>} />
      <Route path="/orders/:id" element={
        <Suspense fallback={<LoadingContainer />}>
          <AuthenticatedRoute><Order /></AuthenticatedRoute>
        </Suspense>} />
      {/* Finally, catch all unmatched routes */}
      <Route path="*" element={
        <Suspense fallback={<LoadingContainer />}>
          <NotFound />
        </Suspense>} />;
    </Routes>
  );
}