import { ChatCompletionTool } from "openai/resources";
import { ObjectPKInput } from "../aws/DynamoDbApiTypes";
import { BaseDynamoClass, DynamoKey } from "./BaseDynamoClass";


export type AiPromptSetProps = {
    analyseTopic : AiPrompt;
    createTitleAndSynopsisOptions : AiPrompt;
    identifyCharacters : AiPrompt;
    createStory : AiPrompt;
    regeneratePage : AiPrompt;
    writeDedication : AiPrompt;
    writeVisualDescriptions : AiPrompt;
    writeCoverVisualDescriptions : AiPrompt;
}

export type AiImagePromptSetProps = {
    createImage : AiImagePrompt;
    createCoverImage : AiImagePrompt;
}

export type AiPromptStringified = {
    name : string,
    generator : string,
    chatCompletionTool : ChatCompletionTool
}

export type AiPrompt = {
    generator : AiPromptGenerator,
    chatCompletionTool : ChatCompletionTool
}
export type AiImagePrompt = {
    generator : AiPromptGenerator,
}

export type AiPromptGenerator = Function;

export type AiPromptSetKeyInput = {
    promptSetId : string
}
export default class AiPromptSet<T extends AiPromptSetProps | AiImagePromptSetProps> extends BaseDynamoClass {

    _promptSetId : string | undefined;
    _name : string;
    _description : string;
    private _example: string;

    prompts : T;

    constructor(name : string, description : string, example : string, props : T){
        super();
        this.prompts = props;
        this._name = name;
        this._description = description;
        this._example = example;
    }

    set promptSetId(promptSetId : string | undefined){
        this.markFieldDirty("_promptSetId");
        this._promptSetId = promptSetId;
    }
    get promptSetId() : string | undefined {
        return this._promptSetId;
    }
    set name(name : string){
        this.markFieldDirty("_name");
        this._name = name;
    }
    get name() : string {
        return this._name;
    }
    set description(description : string){
        this.markFieldDirty("_description");
        this._description = description;
    }
    get description() : string {
        return this._description;
    }
    public get example(): string {
        return this._example;
    }
    public set example(value: string) {
        this._example = value;
    }
    stringifyFunction(fnc : Function){
        return JSON.stringify(fnc, (key, value) => {
            let fnBody;
            if (value instanceof Function || typeof value == 'function') {
                fnBody = value.toString();
                if (fnBody.length < 8 || fnBody.substring(0, 8) !== 'function') { //this is ES6 Arrow Function
                    return '_NuFrRa_' + fnBody;
                }
                return fnBody;
            }
            if (value instanceof RegExp) {
                return '_PxEgEr_' + value;
            }
            return value;
        });
    }
    parseFunction(fnc : string){
        return JSON.parse(fnc, (key, value) => {
            const prefix = value.substring(0, 8);
            if (prefix === 'function') {
                return eval('(' + value + ')');
            }
            if (prefix === '_PxEgEr_') {
                return eval(value.slice(8));
            }
            if (prefix === '_NuFrRa_') {
                return eval(value.slice(8));
            }
            return value;
        });
    }
    getPKPrefix(): string {
        return "PROMPTSET#";
    }
    getSKPrefix(props: object | undefined): string {
        return "PROMPTSET#"
    }
    getPK(): string {
        return this.getPKPrefix() + this.promptSetId;
    }
    getSK(): string {
        return this.getSKPrefix(this) + this.promptSetId;
    }
    getIndexPermissionKey(indexNumber: number, item: BaseDynamoClass): DynamoKey {
        throw new Error("Method not implemented.");
    }
    getParentKey(): DynamoKey | undefined {
        return undefined;
    }
    getParentKeyInput(): ObjectPKInput | undefined {
        return undefined;
    }
    getParentTableName(): string | undefined {
        return undefined;
    }
    generateKey(input: any): DynamoKey {
        return {
            PK: this.generatePK(input),
            SK: this.getSK()
        }
    }
    generatePK(input: AiPromptSetKeyInput): string {
        return this.getPKPrefix() + input.promptSetId;
    }
    generateGSIPK(index: number, key: object): string {
        throw new Error("Method not implemented.");
    }
    generateGSISK(index: number, key: object | undefined): string {
        throw new Error("Method not implemented.");
    }
    getGlobalIndices(): object {
        return {};
    }
    getColumnsForExport(): string[] {
        throw new Error("Method not implemented.");
    }
    getObjectForExport(): object {
        throw new Error("Method not implemented.");
    }

}