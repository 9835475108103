import { MouseEventHandler, ReactNode} from "react";
import { ButtonProps, OverlayTrigger, Tooltip, Spinner } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import { OverlayChildren } from "react-bootstrap/esm/Overlay";
import { BsExclamationDiamond } from "react-icons/bs";

export const defaultToolips = {
  NO_PERMISSION: "You do not have permission to perform this action",
  LIMIT_HIT: "You are not able to create more stories until you've ordered your first story"
}

export type EnhancedButtonProps = ButtonProps & {
  isLoading? : boolean
  onClick? : MouseEventHandler,
  className? : string,
  containerClassName? : string,
  tooltip? : string,
  isWarning? : boolean,
  warningOnClick? : MouseEventHandler,
  disabledTooltip? : string,
  overlayComponent? : OverlayChildren,
  block? : boolean,
  circle? : boolean,
  variant? : string,
  children? : ReactNode,
  [key: string] : unknown
}

export const EnhancedButton = ({isLoading, onClick, className, containerClassName, tooltip, isWarning, warningOnClick, disabled, disabledTooltip, overlayComponent, block, children, circle, variant, ...childProps} : EnhancedButtonProps) => {

  function renderButton({isLoading, disabled, isWarning, className, containerClassName, children, ...childProps} : EnhancedButtonProps){
    return(
      <div className={`${block ? "d-grid" : ""}${containerClassName ? containerClassName : ""}`}>
        <Button
          {...childProps}
          variant={variant}
          disabled={isLoading || disabled}
          className={`btn-loading justify-content-center ${circle ? "px-0 py-0 rounded-circle" : !isLoading ? "px-3" : "px-2"} ${className}`}
        >
          {isLoading && (<><Spinner size="sm" /><span> </span></>)}
          {isWarning && <BsExclamationDiamond size="14" className="mx-2"/>}
          {children}
        </Button>
      </div>
    );
  }

  return (
    <>
      {disabledTooltip && disabled ? (
          <OverlayTrigger overlay={overlayComponent ? overlayComponent : <Tooltip>{disabledTooltip}</Tooltip>}>
              <div className={`mb-2 ${block ? "d-grid" : ""}`} style={{display: "inline-block"}}>
                {renderButton({isLoading, disabled, isWarning, className, children, ...childProps, onClick: (isWarning ? warningOnClick : onClick), style: {pointerEvents: "none"}})}
              </div>
          </OverlayTrigger>
      ) : tooltip ? (
          <OverlayTrigger overlay={overlayComponent ? overlayComponent : <Tooltip>{tooltip}</Tooltip>}>
            {renderButton({isLoading, disabled, isWarning, className, containerClassName, children, ...childProps, onClick: (isWarning ? warningOnClick : onClick)})}
          </OverlayTrigger>
        ) : renderButton({isLoading, disabled, isWarning, className, containerClassName, children, ...childProps, onClick: (isWarning ? warningOnClick : onClick)})
      }
    </>
  );
}