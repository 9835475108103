import { AlertVariant } from "@fableous/core/model/Enums";
import { createContext, FC, useContext } from "react";

export type ErrorContextValues = {
	error? : Error,
    setError : (error : unknown) => void
}

export type UserMessage = {
    title : string,
    variant? : AlertVariant,
    body: string, buttonText : string,
    cancelButtonText? : string
    cancelCallback?: () => void,
    callback?: () => void,
}
export type UserMessageModal = UserMessage & {
    component: FC<unknown>,
    [key : string] : object
};
export type UserAlert = {
    message : string,
    variant : string,
}

export type InfoContextValues = {
    setUserMessage : (userMessage : UserMessage) => void,
    setUserAlert : (userAlert : UserAlert) => void,
    setUserMessageModal : (userMessageModal : UserMessageModal) => void,
}

export const ErrorContext = createContext<ErrorContextValues | undefined>(undefined);

export function useErrorContext() : ErrorContextValues {
  return useContext(ErrorContext) as ErrorContextValues;
}

export const InfoContext = createContext<InfoContextValues | undefined>(undefined);

export function useInfoContext() : InfoContextValues {
  return useContext(InfoContext) as InfoContextValues;
}