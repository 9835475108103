import { RefObject, useCallback, useEffect, useState } from "react";
import { useDimensions } from "./useDimensions";

export function useCalculatedDimensions(componentRef : RefObject<HTMLDivElement>, originalHeight : number, originalWidth : number, preferredMaxHeight? : number) {
    const {container, window} = useDimensions(componentRef);
    const [height, setHeight] = useState<number>();
    const [width, setWidth] = useState<number>();
    const [maxHeight, setMaxHeight] = useState<number>();
    const [maxWidth, setMaxWidth] = useState<number>();

    const calculateDimensions = useCallback((originalWidth : number, originalHeight : number) => {
      const dimensions = {maxHeight, maxWidth, height, width};
      const containerRatio = container.width === 0 ? container.height / originalHeight : container.width / originalWidth;
      const bootstrapHeight = decideHeight(window.bootstrapSize);
      const maxWindowHeight = window.height ? Math.min(bootstrapHeight, window.height) : bootstrapHeight;
      dimensions.maxHeight = preferredMaxHeight !== undefined && preferredMaxHeight < maxWindowHeight ? preferredMaxHeight : maxWindowHeight;
      dimensions.maxWidth = dimensions.maxHeight * (originalWidth / originalHeight);
      if(originalHeight * containerRatio > dimensions.maxHeight){
          dimensions.height = dimensions.maxHeight;
          dimensions.width = dimensions.maxWidth;
      }else{
        dimensions.height = originalHeight * containerRatio;
        dimensions.width = dimensions.height * (originalWidth / originalHeight);
      }
      return dimensions;
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [container.height, container.width, window.bootstrapSize]);
  
    useEffect(() => {
      const dimensions = calculateDimensions(originalWidth || container.width, originalHeight || container.height);
      setHeight(dimensions.height);
      setWidth(dimensions.width);
      setMaxHeight(dimensions.maxHeight);
      setMaxWidth(dimensions.maxWidth);
    },[container, calculateDimensions, originalWidth, originalHeight]);
  
    function decideHeight(bootstrapSize : string | undefined){
      switch (bootstrapSize){
          case "xs":
              return 500;
          default:
              return decideWidth(bootstrapSize);
      }
    }
  
    function decideWidth(bootstrapSize : string | undefined){
      switch (bootstrapSize){
          case "xxl":
              return 1320;
          case "xl":
              return 1140;
          case "lg":
              return 960;
          case "md":
              return 720;
          case "sm":
              return 540;
          case "xs":
              return 0;
          default:
              return 0;
      }
    }

    return {
      height,
      width,
      maxHeight,
      maxWidth,
      calculateDimensions
    }
  }