import { useEffect, useState } from "react";

const bootstrapSizing = {
    xs: 0,
    sm: 576,
    md: 768,
    lg: 992,
    xl: 1200
  };

export function useWindowDimensions() {
    const [width, setWidth] = useState<number>();
    const [height, setHeight] = useState<number>();
    const [bootstrapSize, setBootstrapSize] = useState<"xs" | "sm" | "md" | "lg" | "xl">();

    function metBreakpoint(bsSize : "xs" | "sm" | "md" | "lg" | "xl"){
      return bootstrapSize !== undefined && bootstrapSizing[bsSize] <= bootstrapSizing[bootstrapSize];
    }
  
    useEffect(() => {
      function handleResize(){
        if(window){
          const w = window.innerWidth;
          setWidth(window.innerWidth);
          setHeight(window.innerHeight);
          let bsSize : "xs" | "sm" | "md" | "lg" | "xl" = "xs";
          if(w >= bootstrapSizing.xl){
            bsSize = "xl";
          }else if(w >= bootstrapSizing.lg){
            bsSize = "lg"
          }else if(w >= bootstrapSizing.md){
            bsSize = "md";
          }else if(w >= bootstrapSizing.sm){
            bsSize = "sm"
          }
          setBootstrapSize(bsSize);
        }
      }
      handleResize();
      window.addEventListener('resize', handleResize);
      return () => window.removeEventListener('resize', handleResize);
    },[]);

    return {
      width,
      height,
      bootstrapSize,
      metBreakpoint
    };
  }