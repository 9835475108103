import { ChatCompletionTool } from "openai/resources";
import AiPromptSet, { AiImagePromptSetProps, AiPromptGenerator, AiPromptSetProps } from "../model/AiPromptSet";
import { Character } from "../model/Story";

export const pageQty = 15;
export const sentencesPerPageLower = 2;
export const sentencesPerPageUpper = 3;
export const defaultTextStyle = "There should be rhymes"
export const alternateTextStyle = "There should be a distinct beginning, middle and end. Make up plot if there isn't enough context in the topic";

//Image styles
export const animeStyle = "japanese anime, with cell shading, warm tones, slightly faded watercolour and lots of primary colours.";
export const handDrawnStyle = "loose expressive line art, sketch-like strokes, minimalist barely there, whimsical characters, dynamic poses, wide range of emotions, vibrant watercolours, hand drawn feel and narrative quality.";
export const mangaStyle = "Japanese manga, action packed, characters should have large expressive eyes, stylized hair, dramatic expressions. There should be clean lines, high contrast and rich colours.";
export const fairytaleStyle = "fairytale imagery, mythical imagery, quaint, soft flowing lines, slightly muted pastels, dreamlike, glowing, shimmering, serene and a sense of wonder";
export const comicStyle = "western comic book. Characters should appear as superheroes or similar comic book characters. Use vibrant colours, shar contrasts, motion blur, dramatic shading and bold lines.";
export const bwStyle = "very basic line drawn black and white illustration. Use a mix of lines and shading. There should be no colour";

export const title = () => `Write a \"{title}\" page for the book. The title should be the name of the book, no more than four words.`;
export const visuals = () => `Create a detailed description of the visuals for each page that can be used as an image prompt for generation. Make sure the characters are referenced by name. Make sure the description for each page does not reference other page descriptions. Do not describe what characters look like, but describe what actions they are performing and describe their environment and other objects in specific detail.`;
export const coverVisuals = () => `Create a detailed description of the visuals for the front cover and back cover of the book. The front cover and back cover should have no text in the image. Make sure the characters are referenced by name. Do not describe what characters look like, but describe what actions they are performing and describe their environment and other objects in specific detail.`;

export const visualsTerse : AiPromptGenerator = () => `Please provide a set of key words that describe the visuals for each page in the book to be used by an image generator. In 2 words, state the scene location. State up to 3 objects that are present in the scene, but not worn or held by a character, with 1 adjective for each. State up to 3 adjectives to describe the scene. For each character, use 3 words to state how they are positioned and 3 words to describe their actions.` //, 2 words to describe their expression and 3 words to describe their clothing or objects (only if it is specifically mentioned in the text).`
export const coverVisualsTerse : AiPromptGenerator  = () => `Please provide a set of key words that describe the visuals for the front cover and back cover of the book to be used by an image generator. In 2 words, state the scene location. State up to 3 objects that are present in the scene, but not worn or held by a character, with 1 adjective for each. State up to 3 adjectives to describe the scene. For each character, use 3 words to state how they are positioned and 3 words to describe their actions.` //, 2 words to describe their expression and 3 words to describe their clothing or objects (only if it is specifically mentioned in the text).`

/* export const imagePrompt = (prompt : string, characters : Character[]) => {
    let fullPrompt = `Generate an image for the following prompt: ${prompt} `;
    characters.forEach((char) => {
        if(char.name && prompt.toLowerCase().includes(char.name.toLowerCase())){
            fullPrompt += `Whenever ${char.name} is mentioned in the prompt, include the following description with no omissions: ${char.visualDescription}. If the prompt doesn't describe ${char.name}'s clothing the prompt should also include: ${char.clothing}. If the prompt doesn't describe ${char.name}'s expression the prompt should also include: "${char.name} is smiling". `;
        }
    });
    fullPrompt += `The image should be in the style of ${animeStyle}`;
    return fullPrompt;
} */

export const imagePromptTerse : AiPromptGenerator = (style : string) => {
    return (obj : StoryVisualDescriptionTerseResponse, characters : Character[]) => {
        let fullPrompt = `Generate an image for a scene described as ${obj.summary}. Set in the a location of ${obj.sceneLocation} with ${obj.sceneObjects.join(', ')} that is ${obj.sceneAdjectives.join(', ')}. `;
        obj.characters.forEach((char : CharacterSceneInformation) => {
            //Char is ChatGPT generated scene information, charDesc is user entered character properties
            fullPrompt += `${char.name}`;
            const charDesc = characters.find((c) => char.name.toLowerCase() === c?.name?.toLowerCase());
            if(charDesc?.visualDescription) fullPrompt += ` looks like ${charDesc.visualDescription}`;
            if(charDesc?.ageBracket) fullPrompt += `, is a ${charDesc.ageBracket}`;
            if(charDesc?.gender && charDesc.gender !== "unknown" && charDesc.typeOfCharacter !== "object") fullPrompt += ` of ${charDesc.gender} gender`;
            if(charDesc && charDesc.skinColour) fullPrompt += ` with ${charDesc.skinColour} skin`;
            if(charDesc && charDesc.eyeColour) fullPrompt += `, ${charDesc.eyeColour} eyes`;
            if(charDesc && charDesc.hairColour) fullPrompt += `, and ${charDesc.hairLength} length, ${charDesc.hairStyle} ${charDesc.hairColour} hair`;
            if(charDesc && charDesc.clothing) fullPrompt += `. ${char.name} is wearing ${charDesc.clothing}`;
            fullPrompt += ", is ";
            fullPrompt += char.positioning + ", " + char.actions + ". ";
            //fullPrompt += char.name + " is " + char.expression + ". ";
            //if(char.clothingAndObjects) fullPrompt += char.name + " has " + char.clothingAndObjects + ". ";
        });
        fullPrompt += `The image should be in the style of ${style}`;
        return fullPrompt;
    }
}

export type CharactersResponse = {
    characters : Array<{
        name: string; // Required as per JSON Schema
        gender?: 'male' | 'female' | 'unknown'; // Enum type, optional as not in the required array
        mainCharacter: boolean; // Required as per JSON Schema
        typeOfCharacter: 'person' | 'animal' | 'object'; // Required as per JSON Schema
        visualDescription? : string;
    }>
};

export type StoryTextResponse = {
    pages : Array<{
        page : number,
        text: string
    }>
}

export type StoryVisualDescriptionsResponse = {
    visualDescriptions: Array<{
        page : number,
        visualDescription : string
    }>
}

export type CoverVisualDescriptionResponse = {
    frontCover : string,
    backCover : string
}

export type StoryVisualDescriptionsTerseResponse = {
    visualDescriptions: Array<StoryVisualDescriptionTerseResponse>
}

export type StoryVisualDescriptionTerseResponse = {
    page?: number;  // The page number of the story
    summary : string,
    sceneLocation: string;  // The 2-word scene location
    sceneObjects: string[];  // An array of objects in the scene, described with 1 adjective, up to 3 items
    sceneAdjectives: string[];  // An array of single-word adjectives to describe the scene, up to 3 items
    characters: Array<CharacterSceneInformation>
}

export type CharacterSceneInformation = {
    name: string;  // The character's name
    positioning: string;  // Up to 3 words describing their location in the scene
    actions: string;  // Up to 3 words describing their actions in the scene
    //expression: string;  // Up to 2 words describing their expression in the scene
    //clothingAndObjects: string;  // Up to 3 words describing their clothing or objects, only if mentioned in the text
}

export type CoverVisualDescriptionTerseResponse = {
    frontCover : StoryVisualDescriptionTerseResponse,
    backCover : StoryVisualDescriptionTerseResponse
}

export const titleFunction : ChatCompletionTool = {
    type: "function",
    function: {
        name: "titleFunction",
        description: "Creates a new object representing the title to a story",
        parameters: {
            type: "object",
            properties: {
                title: {
                    type: "string",
                    description: "a short title for the story"

                }
            },
            required: ['title']
        }
    }
}


/* export const visualDescriptionFunction : ChatCompletionTool = {
    type: "function",
    function: {
        name: "visualDescriptionFunction",
        description: "Creates a new object representing a set of visual descriptions for a story",
        parameters: {
            type: "object",
            properties: {
                visualDescriptions: {
                    type: "array",
                    items: {
                        type: "object",
                        properties: {
                            page: {
                                type: "number",
                                description: "the page number of the story"
                            },
                            visualDescription: {
                                type: "string",
                                description: "the visual description of the scene"
                            }
                        },
                        required: ["page","visualDescription"]
                    }
                }
            }
        }
    }
} */
/* export const coverVisualDescriptionFunction : ChatCompletionTool = {
    type: "function",
    function: {
        name: "coverVisualDescriptionFunction",
        description: "Creates a new object representing the visual description of a front cover and a back cover for a story",
        parameters: {
            type: "object",
            properties: {
                coverVisualDescriptions: {
                    type: "object",
                    properties : {
                        frontCover: {
                            type: "string",
                            description: "the visual description of the scene on the front cover"
                        },
                        backCover: {
                            type: "string",
                            description: "the visual description of the scene on the back cover"
                        }
                    },
                    required: ["frontCover","backCover"]
                }
            }
        }
    }
} */
export const visualDescriptionFunctionTerse : ChatCompletionTool = {
    type: "function",
    function: {
        name: "visualDescriptionFunction",
        description: "Creates a new object representing a set of visual descriptions for a story",
        parameters: {
            type: "object",
            properties: {
                visualDescriptions: {
                    type: "array",
                    items: {
                        type: "object",
                        properties: {
                            page: {
                                type: "number",
                                description: "the page number of the story"
                            },
                            summary : {
                                type: "string",
                                description: "up to 10 words describing the scene"
                            },
                            sceneLocation: {
                                type: "string",
                                description: "the 2 word scene location"
                            },
                            sceneObjects: {
                                type: "array",
                                items: {
                                    type: "string",
                                    description: "an object in the scene not worn or held by a character, described with 1 adjective"
                                },
                                maxItems: 3
                            },
                            sceneAdjectives: {
                                type: "array",
                                items: {
                                    type: "string",
                                    description: "a single word adjective to describe the scene"
                                },
                                maxItems: 3
                            },
                            characters: {
                                type: "array",
                                items: {
                                    type: "object",
                                    properties: {
                                        name: {
                                            type: "string",
                                            description: "the character's name"
                                        },
                                        positioning: {
                                            type: "string",
                                            description: "up to 3 words describing their location in the scene"
                                        },
                                        actions: {
                                            type: "string",
                                            descriuption: "up to 3 words describing their actions in the scene"
                                        },
                                        /* expression: {
                                            type: "string",
                                            descriuption: "up to 2 words describing their expression in the scene"
                                        },
                                        clothingAndObjects: {
                                            type: "string",
                                            descriuption: "up to 3 words describing their clothing or objects, only if they are mentioned in the text"
                                        } */
                                    },
                                    required: ["name", "positioning", "actions"]//, "expression", "clothingAndObjects"]
                                }
                            }
                        },
                        required: ["page","summary", "sceneLocation", "sceneObjects", "sceneAdjectives", "characters"]
                    },
                    minItems: 15,
                    maxItems: 15
                }
            }
        }
    }
}
export const coverVisualDescriptionFunctionTerse : ChatCompletionTool = {
    type: "function",
    function: {
        name: "coverVisualDescriptionFunction",
        description: "Creates a new object representing the visual description of a front cover and a back cover for a story",
        parameters: {
            type: "object",
            properties: {
                coverVisualDescriptions: {
                    type: "object",
                    properties : {
                        frontCover: {
                            type: "object",
                            properties: {
                                summary : {
                                    type: "string",
                                    description: "up to 10 words describing the scene"
                                },
                                sceneLocation: {
                                    type: "string",
                                    description: "the 2 word scene location"
                                },
                                sceneObjects: {
                                    type: "array",
                                    items: {
                                        type: "string",
                                        description: "an object in the scene not worn or held by a character, described with 1 adjective"
                                    },
                                    maxItems: 3
                                },
                                sceneAdjectives: {
                                    type: "array",
                                    items: {
                                        type: "string",
                                        description: "a single word adjective to describe the scene"
                                    },
                                    maxItems: 3
                                },
                                characters: {
                                    type: "array",
                                    items: {
                                        type: "object",
                                        properties: {
                                            name: {
                                                type: "string",
                                                description: "the character's name"
                                            },
                                            positioning: {
                                                type: "string",
                                                description: "up to 3 words describing their location in the scene"
                                            },
                                            actions: {
                                                type: "string",
                                                descriuption: "up to 3 words describing their actions in the scene"
                                            },
                                            /* expression: {
                                                type: "string",
                                                descriuption: "up to 2 words describing their expression in the scene"
                                            },
                                            clothingAndObjects: {
                                                type: "string",
                                                descriuption: "up to 3 words describing their clothing or objects, only if they are mentioned in the text"
                                            } */
                                        },
                                        required: ["name", "positioning", "actions"]//, "expression", "clothingAndObjects"]
                                    }
                                }
                            },
                            required: ["page","summary", "sceneLocation", "sceneObjects", "sceneAdjectives", "characters"]
                        },
                        backCover: {
                            type: "object",
                            properties: {
                                summary : {
                                    type: "string",
                                    description: "up to 10 words describing the scene"
                                },
                                sceneLocation: {
                                    type: "string",
                                    description: "the 2 word scene location"
                                },
                                sceneObjects: {
                                    type: "array",
                                    items: {
                                        type: "string",
                                        description: "an object in the scene not worn or held by a character, described with 1 adjective"
                                    },
                                    maxItems: 3
                                },
                                sceneAdjectives: {
                                    type: "array",
                                    items: {
                                        type: "string",
                                        description: "a single word adjective to describe the scene"
                                    },
                                    maxItems: 3
                                },
                                characters: {
                                    type: "array",
                                    items: {
                                        type: "object",
                                        properties: {
                                            name: {
                                                type: "string",
                                                description: "the character's name"
                                            },
                                            positioning: {
                                                type: "string",
                                                description: "up to 3 words describing their location in the scene"
                                            },
                                            actions: {
                                                type: "string",
                                                descriuption: "up to 3 words describing their actions in the scene"
                                            },
                                            expression: {
                                                type: "string",
                                                descriuption: "up to 2 words describing their expression in the scene"
                                            },
                                            clothingAndObjects: {
                                                type: "string",
                                                descriuption: "up to 3 words describing their clothing or objects, only if they are mentioned in the text"
                                            }
                                        },
                                        required: ["name", "positioning", "actions", "expression", "clothingAndObjects"]
                                    }
                                }
                            },
                            required: ["page","summary", "sceneLocation", "sceneObjects", "sceneAdjectives", "characters"]
                        }
                    },
                    required: ["frontCover","backCover"]
                }
            }
        }
    }
}

export const analyseTopicGenerator : AiPromptGenerator = (topic : string) => `Analyse the following topic of a story and identify whether there is a main character, whether the main character has a name, how many characters there are and assess whether it could be used to create a plot. The topic is ${topic}`;

export const analyseTopicCompletionTool : ChatCompletionTool = {
    type: "function",
    function: {
        name: "analyseTopicFunction",
        description: "Creates a new object representing analysis of a story topic",
        parameters: {
            type: "object",
            properties: {
                mainCharacter: {
                    type: "boolean",
                    description: "Whether there is a main character"

                },
                namedCharacter: {
                    type: "boolean",
                    description: "Whether the main character has a name"

                },
                numberOfCharacters: {
                    type: "number",
                    description: "How many characters are specified in the topic"

                },
                presenceOfPlot: {
                    type: "boolean",
                    description: "Is there enough context to create a plot from the topic"

                }
            },
            required: ['mainCharacter', 'namedCharacter', 'numberOfCharacters', 'presenceOfPlot']
        }
    }
}

export const createTitleOptionsCompletionTool : ChatCompletionTool = {
    type: "function",
    function: {
        name: "createTitleOptionsFunction",
        description: "Creates a new object representing 3 options for a story title",
        parameters: {
            type: "object",
            properties: {
                options: {
                    type: "array",
                    items: {
                        type: "object",
                        properties: {
                            index: {
                                type: "number",
                                description: "The index of the title option, which will be used to choose"
                            },
                            title: {
                                type: "string",
                                description: "The title of the story. Max 4 words"
                            },
                            synopsis: {
                                type: "string",
                                description: "The synopsis of the story. Max 2 sentences."
                            }
                        },
                        required: ["index","title", "synopsis"]
                    },
                    minItems: 3,
                    maxItems: 3
                }
            }
        }
    }
}

export const characterIdentify : AiPromptGenerator = (selectedIndex : number) => `I'd like to select the title & synopsis option ${selectedIndex}. Before writing the story, provide a list of characters, no more than 3. Specify their name, if they are the main character, their gender and what type of character they are. If they are not a person, include a short visual description of no more than 10 words.`;

export const characterIdentifyFunction : ChatCompletionTool = {
    type: "function",
    function: {
        name: "characterIdentifyFunction",
        description: "Creates a new object representing a character in story",
        parameters: {
            type: "object",
            properties: {
                characters: {
                    type: "array",
                    items: {
                        type: "object",
                        properties: {
                            name: {
                                type: "string",
                                description: "the name of the character"
                            },
                            gender: {
                                type: "string",
                                description: "the likely gender of the character",
                                enum: ["male", "female", "unknown"]
                            },
                            mainCharacter: {
                                type: "boolean",
                                description: "whether they are likely to be a main character in the story"
                            },
                            typeOfCharacter: {
                                type: "string",
                                description: "Are they a person, animal or object",
                                enum: ["person", "animal", "object"]
                            },
                            visualDescription : {
                                type: "string",
                                description: "A short visual description if they are not a person"
                            }
                        },
                        required: ["name","mainCharacter", "gender", "hasName", "typeOfCharacter"]
                    }
                }
            }
        }
    }
}

export const dedication : AiPromptGenerator = (to : string, from : string) => `Write a short dedication to ${to} from ${from} to display on the inside cover of the book. Maximum 20 words.`;

export const dedicationFunction : ChatCompletionTool = {
    type: "function",
    function: {
        name: "dedicationFunction",
        description: "Creates a new object representing the dedication for a story",
        parameters: {
            type: "object",
            properties: {
                dedication: {
                    type: "string",
                    description: "a short dedication for the story"

                }
            },
            required: ['dedication']
        }
    }
}

export const standardCharacterGenerator = (characters : Character[]) => {
    let prompt = `Ignore any previous characters and use this list of characters for the story: `;
    characters.forEach((char) => {
        prompt += char.name + " who is a ";
        if(char.gender) prompt += char.gender
        if(char.ageBracket) prompt +=  ", " + char.ageBracket;
        if(char.typeOfCharacter) prompt += ", " + char.typeOfCharacter;
        if(char.visualDescription) prompt += ", " + char.visualDescription;
        prompt += ". ";
    });
    return prompt;
}

export const babyBedtimeCreateTitleOptionsGenerator : AiPromptGenerator = (topic : string) => `Please use Australian English for this entire chat. You are an accomplished children's author and you are going to write an engaging, great 15 page story for a baby between 0 and 3 years old. Before we begin, provide 3 different options for a title and synopsis of the book for me to choose from based on a topic. Each title should be no more than 4 words long. Each synopsis should be no more than 2 sentences long. Add an index to each option so I can tell you which one to use. The topic is ${topic}`;
export const babyBedtimeStoryGenerator : AiPromptGenerator = (characters : Character[]) => `Please now write the story. It should be exactly 15 pages long. Do not include a title page. The story should be for a baby between 0 and 3 years old. The text should be simple, captivating and max 1 sentence per page. Include onomatopoeic words, sounds and noises. Include rhymes. ` + standardCharacterGenerator(characters);

export const fairytaleCreateTitleOptionsGenerator : AiPromptGenerator = (topic : string) => `Please use Australian English for this entire chat. You are an accomplished children's author and you are going to write an engaging, great 15 page story for a child between 3 and 6 years old. Before we begin, provide 3 different options for a title and synopsis of the book for me to choose from based on a topic. Each title should be no more than 4 words long. Each synopsis should be no more than 2 sentences long. Add an index to each option so I can tell you which one to use. The topic is ${topic}`;
export const fairytaleStoryGenerator : AiPromptGenerator = (characters : Character[]) => `Please now write the story. It should be exactly 15 pages long. Do not include a title page. The story should be for a child between 3 and 6 years old. The text should be imaginative and fantastical, and suggestive of a fairy tale. ` + standardCharacterGenerator(characters);

export const dreamCreateTitleOptionsGenerator : AiPromptGenerator = (topic : string) => `Please use Australian English for this entire chat. You are an accomplished children's author and you are going to write an engaging, great 15 page story for a child between 3 and 6 years old. Before we begin, provide 3 different options for a title and synopsis of the book for me to choose from based on a topic. Each title should be no more than 4 words long. Each synopsis should be no more than 2 sentences long. Add an index to each option so I can tell you which one to use. The topic is ${topic}`;
export const dreamStoryGenerator : AiPromptGenerator = (characters : Character[]) => `Please now write the story. It should be exactly 15 pages long. Do not include a title page. The story should be for a child between 3 and 6 years old. The story should be inspirational and encourage the reader to follow their dreams, the story should have rhymes. ` + standardCharacterGenerator(characters);

export const storyCompletionTool : ChatCompletionTool = {
    type: "function",
    function: {
        name: "storyFunction",
        description: "Creates a new object representing a story",
        parameters: {
            type: "object",
            properties: {
                pages: {
                    type: "array",
                    items: {
                        type: "object",
                        properties: {
                            page: {
                                type: "number",
                                description: "the page number of the story"
                            },
                            text: {
                                type: "string",
                                description: "the text for the page of the story"
                            }
                        },
                        required: ["page","text"]
                    },
                    minItems: 15,
                    maxItems: 15
                }
            }
        }
    }
}

const storyPageRegenerator : AiPromptGenerator = (page : number) => `Please rewrite the text for page ${page}, follow the same writing style.`;

export const storyPageRegeneratorCompletionTool : ChatCompletionTool = {
    type: "function",
    function: {
        name: "storyFunction",
        description: "Creates a new object representing a story page",
        parameters: {
            type: "object",
            properties: {
                page: {
                    type: "number",
                    description: "the page number of the story"
                },
                text: {
                    type: "string",
                    description: "the text for the page of the story"
                }
            },
            required: ["page","text"]
        }
    }
}

const promptSetDefaults = {
    analyseTopic: {
        generator: analyseTopicGenerator,
        chatCompletionTool: analyseTopicCompletionTool
    },
    identifyCharacters: {
        generator: characterIdentify,
        chatCompletionTool: characterIdentifyFunction
    },
    writeDedication: {
        generator: dedication,
        chatCompletionTool: dedicationFunction
    },
    regeneratePage: {
        generator: storyPageRegenerator,
        chatCompletionTool: storyPageRegeneratorCompletionTool
    },
    writeVisualDescriptions: {
        generator: visualsTerse,
        chatCompletionTool: visualDescriptionFunctionTerse
    },
    writeCoverVisualDescriptions: {
        generator: coverVisualsTerse,
        chatCompletionTool: coverVisualDescriptionFunctionTerse
    }
};

export const BabyBedtimePromptSet : AiPromptSet<AiPromptSetProps> = new AiPromptSet("Baby bedtime", "Engaging stories with fun sounds to be shared at baby bedtime", "'Peekaboo!' With a kick, the blanket sails high.", {
    ...promptSetDefaults,
    createStory: {
        generator: babyBedtimeStoryGenerator,
        chatCompletionTool: storyCompletionTool
    },
    createTitleAndSynopsisOptions: {
        generator: babyBedtimeCreateTitleOptionsGenerator,
        chatCompletionTool: createTitleOptionsCompletionTool
    }
});

export const FairytalesPromptSet : AiPromptSet<AiPromptSetProps> = new AiPromptSet("Fairytales", "Classic fairy tale story telling", "The map was old and crinkly, with a big red X marked at the edge of the forest. \"A treasure, here in my home?\"", {
    ...promptSetDefaults,
    createStory: {
        generator: fairytaleStoryGenerator,
        chatCompletionTool: storyCompletionTool
    },
    createTitleAndSynopsisOptions: {
        generator: fairytaleCreateTitleOptionsGenerator,
        chatCompletionTool: createTitleOptionsCompletionTool
    }
});

export const DreamPromptSet : AiPromptSet<AiPromptSetProps> = new AiPromptSet("Big dreams", "Stories for inspiration, for dreamers", "Billy learned to pass and play,\nAs a team, in every way.\n\"Finding friends along the ride,\nHelps your dreams to open wide.\"",{
    ...promptSetDefaults,
    createStory: {
        generator: dreamStoryGenerator,
        chatCompletionTool: storyCompletionTool
    },
    createTitleAndSynopsisOptions: {
        generator: dreamCreateTitleOptionsGenerator,
        chatCompletionTool: createTitleOptionsCompletionTool
    }
});

export const handDrawn : AiPromptSet<AiImagePromptSetProps> = new AiPromptSet("Expressive sketch", "Loose expressive hand drawn feel with vibrant watercolours.", "",{
    createImage: {
        generator: imagePromptTerse(handDrawnStyle)
    },
    createCoverImage: {
        generator: imagePromptTerse(handDrawnStyle)
    }
});

export const anime : AiPromptSet<AiImagePromptSetProps> = new AiPromptSet("Anime", "Cell shading, warm faded tones and anime style.", "", {
    createImage: {
        generator: imagePromptTerse(animeStyle)
    },
    createCoverImage: {
        generator: imagePromptTerse(animeStyle)
    }
});
export const manga : AiPromptSet<AiImagePromptSetProps> = new AiPromptSet("Manga", "Highly stylized, cartoon imagery", "", {
    createImage: {
        generator: imagePromptTerse(mangaStyle)
    },
    createCoverImage: {
        generator: imagePromptTerse(mangaStyle)
    }
});
export const fairytale : AiPromptSet<AiImagePromptSetProps> = new AiPromptSet("Fairytale", "Dreamlike images with pastel tones", "", {
    createImage: {
        generator: imagePromptTerse(fairytaleStyle)
    },
    createCoverImage: {
        generator: imagePromptTerse(fairytaleStyle)
    }
});
export const comic : AiPromptSet<AiImagePromptSetProps> = new AiPromptSet("Comic", "High colour contrast and superheroes", "", {
    createImage: {
        generator: imagePromptTerse(comicStyle)
    },
    createCoverImage: {
        generator: imagePromptTerse(comicStyle)
    }
});
export const bw : AiPromptSet<AiImagePromptSetProps> = new AiPromptSet("Black and white", "Illustrations with lines and shading.", "", {
    createImage: {
        generator: imagePromptTerse(bwStyle)
    },
    createCoverImage: {
        generator: imagePromptTerse(bwStyle)
    }
});