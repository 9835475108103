import { ReactNode } from "react";
import { Container, Spinner } from "react-bootstrap";
import ResponsiveContainer from "./ResponsiveContainer";

export default function LoadingContainer({children, condition, as, ...props} : {children? : ReactNode, condition? : boolean, as? : React.ElementType}){
    const As = as || Container;
    return <>
        {condition ?
            (<As {...props}>{children}</As>) :
            (<ResponsiveContainer innerContainerClassName="d-flex justify-content-center align-items-center" originalHeight={300} originalWidth={600} {...props}><Spinner/></ResponsiveContainer>)
        }
    </>
}