import { LogLevel } from "@fableous/core/model/Enums";
import {logError, logMessage} from "./sentryLib";

const logger = {
  debug: (message : unknown) => debug(message),
  info: (message : unknown) => info(message),
  warn: (message : unknown) => warn(message),
  error: (error : unknown) => onError(error)
}

function onError(error : unknown) {
  let errorInfo : unknown = {};
  if(!error){
    error = new Error('Unknown error has occurred, error object not passed to error library.');
  }
  let message = (error as object).toString();

  // Auth errors
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  if (!(error instanceof Error) && (error as any).message) {
    errorInfo = error;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    message = (error as any).message as string;
    error = new Error(message);
    // API errors
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } else if ((error as any).config && (error as any).config.url) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (errorInfo as any).url = (error as any).config.url;
  }

  logError(error, errorInfo);
}

function debug(message : unknown){
  logMessage(message, LogLevel.DEBUG);
}

function info(message : unknown){
  logMessage(message, LogLevel.INFO);
}

function warn(message : unknown){
  logMessage(message, LogLevel.WARN);
}

export default logger;